import React from "react"
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from "../../components/Common/PageBanner"
import Footer from "../../components/App/Footer"
import AboutUsContent from "../../components/AboutUs/AboutUsContent"
import OurHistory from "../../components/AboutUs/OurHistory"
import WhyChooseUs from "../../components/AboutUs/WhyChooseUs"
import HowWeWork from "../../components/AboutUs/HowWeWork"
import TeamMembers from "../../components/AboutUs/TeamMembers"
import Testimonials from "../../components/AboutUs/Testimonials"
import Partner from "../../components/AboutUs/Partner"

const AboutUs = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="O Nas"
        homePageText="Start"
        homePageUrl="/"
        activePageText="O Nas"
      />
      <AboutUsContent />
      <OurHistory />
      <WhyChooseUs />
      <HowWeWork />
      <TeamMembers />
      <Testimonials />
      <Partner />
      <Footer />
    </Layout>
  )
}

export default AboutUs
